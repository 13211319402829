import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';

import SEO from '../components/seo';
import Layout from '../components/layout';
import theme from '../utils/theme';

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  height: 100%;
  padding: 1.45rem 1.0875rem;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin-bottom: 2rem;
  flex: 0 0 auto;
`;

const LinkAll = styled(Link)`
  margin-top: 2rem;
  font-size: 0.8rem;
  color: ${theme.light.fgColor};
  text-align: center;
  background-image: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Tags = props => {
  const { tag } = props.pageContext;
  const { edges, totalCount } = props.data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`;

  return (
    <Layout
      main={
        <Content>
          <SEO title={`Posts tagged with ${tag}`} keywords={['blog', 'tags']} />
          <Title>{tagHeader}</Title>
          <ul>
            {edges.map(({ node }) => {
              const { slug } = node.fields;
              const { title, path } = node.frontmatter;
              return (
                <li key={slug}>
                  <Link title={title} to={path}>
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
          <LinkAll title="All tags" to="/tags">
            View all tags
          </LinkAll>
        </Content>
      }
    />
  );
};

export default Tags;
